img {
  max-width: 100%;
}

pre {
  white-space: pre-wrap;
  margin: var(--st-spacer-sm) 0 var(--st-spacer-mlg);
}

.st_content {
  padding-bottom: var(--st-spacer-xl);
}

.st_token {
  cursor: default;
}

.frameHeaderMenu {
  float: right;
  text-align: right;
  padding: 0.8rem 1rem 0 0;
}
