.subnav {
  position: fixed;
  width: 100%;
  z-index: var(--st-zindex-mid);
}

.subnav-multiple-select {
  display: flex;
  align-items: center;
}

.lightbox {
  padding: var(--st-spacer-md);
}

.lightbox--remove {
  margin-bottom: var(--st-spacer-md);
}

.lightbox .image--container {
  position: relative;
  margin-bottom: var(--st-spacer-xs);
}

.lightbox .image--mask {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: calc(100% - 4px);
  display: flex;
  /* TODO: Change to screentone new RGB variable when available */
  background-color: rgba(45, 52, 54, 0.8);
  padding: var(--st-spacer-smd);
  box-sizing: border-box;
  cursor: pointer;
}

.lightbox .image {
  width: 100%;
  border-radius: var(--st-border-radius);
}

.lightbox .image--container:hover > .image--mask {
  visibility: visible;
}

.remove--label {
  margin: 0px;
  display: flex;
  line-height: 1px;
  align-items: center;
  height: fit-content;
}

.loading--label {
  position: absolute;
  top: var(--st-spacer-sm);
  left: var(--st-spacer-sm);
}

.remove--label > svg {
  margin-right: var(--st-spacer-xs);
}

.lightbox .image--container:hover > 
.grid-image-labels-container {
  visibility: hidden;
}

.lightbox .image--container:hover > 
.loading--label {
  visibility: hidden;
}

.pagewrapper-container {
  padding-top: var(--st-btn-height);
  padding-bottom: var(--st-spacer-xl);
}
