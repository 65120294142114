.grid-image-labels-container {
  position: absolute;
  z-index: var(--st-zindex-minor);
  left: var(--st-spacer-sm);
  top: var(--st-spacer-sm);
}

.grid-image-label {
  display: inline-block;
  margin-right: var(--st-spacer-xs);
}

.grid-image-label-icon {
  padding: var(--st-spacer-xs);
}
